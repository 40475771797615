import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import FlexCard from '../components/flexcard';
import GiftCardBackground from '../components/giftcardbackground';
import settings from '../../settings';
import BmwGiftCardGlance from '../components/bmwGlance/bmwGiftCardGlance';

// images
const topbanner = `${settings.IMAGES_BASE_URL}/images/giftcards/gift-card-banner.jpg`;
const giftcardimagenew = `${settings.IMAGES_BASE_URL}/images/giftcards/gift-card-new.jpg`;
const holidayBanner = `${settings.IMAGES_BASE_URL}/images/home/bmw-holiday.jpg`;
const holidayBanner1 = `${settings.IMAGES_BASE_URL}/images/home/bmw-holiday-400.webp`;
const holidayBanner2 = `${settings.IMAGES_BASE_URL}/images/home/bmw-holiday-800.webp`;
const holidayBanner3 = `${settings.IMAGES_BASE_URL}/images/home/bmw-holiday-1200.webp`;
const today = new Date();
const showHolidayBanner = 
today >= new Date(2024,10,8) && 
today < new Date(2025,0,1);

const GiftCards = () => {
  const getFirstCard = () => {
    if (showHolidayBanner) {
      return (
        <FlexCard
          rowType="row"
          image={holidayBanner}
          imageAlt="TURBOCHARGE YOUR HOLIDAY"
          header="TURBOCHARGE YOUR HOLIDAY"
          subheader="20% OFF A GIFT CARD"
          text="Create the perfect holiday with some M Power. Get 20% off a gift card, good toward many classes and Experiences."
          btnText="CALL 888-345-4269 TO BOOK"
          btnStyle="btn-blue-offers"
          phone="tel:888-345-4269"
          srcSet={holidayBanner1 + " 400w, "+ holidayBanner2+" 800w, " + holidayBanner3+" 1200w"  }
          sizes="(max-width: 400px) 400px, (max-width: 800px) 800px, 1200px"
        >
          <p>
            Call <a className="phone-number" href="tel:888-345-4269">888-345-4269</a> to book your class today with promo code <span className="bold">24BMWHPH20.</span>. 
          </p>
          <p>
          Must purchase gift card by 12/31/24. Not valid for M4 GT4 experience, Race License School, M Mixed Reality or Private Instruction. Valid at our South Carolina, California and Indianapolis locations.
          </p>
        </FlexCard>
      )
    } else {
      return (
        <FlexCard
          rowType="row"
          image={giftcardimagenew}
          imageAlt="Give the gift of speed"
          header="Give the gift of speed"
          subheader="THE ULTIMATE OF BMW DRIVER GIFTS"
          text="Give someone a driving gift they’ll never forget. Good toward any class, each BMW gift card is delivered electronically, so it’s also perfect for last-minute gift giving."
          btnText="Call 888-345-4269 to purchase"
          btnStyle="btn-blue"
          externalURL="tel:888-345-4269"
        >
        </FlexCard>
      )
    }
  }

  return (
    <Layout>
      <SEO title="BMW Gift Cards | BMW Performance Driving School" description="Give the gift of speed with the Ultimate of BMW Driver Gifts." />
      <div className="giftcardpage giftcardpagebmw">
          <GiftCardBackground
            imageDesktop={topbanner}
            imageMobile={topbanner}
            imageAlt="BMW Gift Card Top Banner"
          />

          <section className="performance-center">
            <div className="container">
              <div className="performance-center__header">
                <h1>Bmw Gift Cards</h1>
              </div>
            </div>
            {getFirstCard()}
          </section>
          <BmwGiftCardGlance/>
        </div>
    </Layout>
  );
};

export default GiftCards;
