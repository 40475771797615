import React from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';

const BmwGiftCardGlance = props => {
  return (
    <section className="bmw-locations">
      <div className="container">
        <div className="performance-center-locations">
          <h3>BMW PERFORMANCE CENTER GIFT CARDS AT A GLANCE</h3>
          <div className="row">
            <div className="col">
              <div>
                <h5>Do I receive a physical card?</h5>
                <p>No. All BMW gift cards are delivered electronically via email.</p>
              </div>
              <div>
                <h5>Can I use a BMW gift card received at a BMW Center?</h5>
                <p>No. Only BMW gift cards purchased through this website are valid.</p>
              </div>
              <div>
                <h5>Can I use a BMW gift card for any class?</h5>
                <p>Yes. The gift card can be used toward any class, Group Event or Experience at any of our locations.</p>
              </div>
            </div>
            <div className="col">
              <div>
                <h5>Is there a minimum BMW gift card amount?</h5>
                <p>We can create a gift card for any dollar amount.</p>
              </div>
              <div>
                <h5>Can I get a BMW gift card refunded? </h5>
                <p>No. All gift card purchases are final.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BmwGiftCardGlance;